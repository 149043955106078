
















































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { usersAPI } from '@/api/users.api'
import { ChangePasswordFormType } from '@/types/users'

@Component({
  components: {
    InputCommon,
  },
})
export default class ChangePasswordForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: String })
  userId!: string

  changePasswordForm: ChangePasswordFormType = {
    id: '',
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  }

  handleCloseDialog(): void {
    this.$emit('close-dialog')
  }

  async handleChangePassword(): Promise<void> {
    const [error] = await usersAPI.usersChangePassword({
      ...this.changePasswordForm,
      id: this.userId,
    })

    if (!error) {
      this.$emit('change-password')
    } else {
      this.handleCloseDialog()
    }
  }
}
