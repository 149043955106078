















import { Component, Vue, Prop } from 'vue-property-decorator'
import { UsersListItemType } from '@/types/users'

@Component
export default class UsersCard extends Vue {
  @Prop({ type: Object })
  user!: UsersListItemType

  handleShowUserDetail(): void {
    this.$emit('show-user', this.user.id)
  }
}
