



































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { UserResponseType } from '@/types/user'

@Component
export default class ProfileInfo extends Vue {
  @Prop({ type: Object })
  profileInfo!: UserResponseType

  get userPhoto(): string {
    return process.env.VUE_APP_BASE_URI + this.profileInfo.photo
  }

  handleEditProfile(): void {
    this.$emit('show-edit-profile')
  }

  handleChangePassword(): void {
    this.$emit('change-password')
  }
}
