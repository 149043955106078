






































































































































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { Form, Upload } from 'element-ui'
import SelectCommon from '@/components/common/SelectCommon.vue'
import { AllVendorsType, VendorOptionsType } from '@/types/vendors'
import { usersAPI } from '@/api/users.api'
import { AddSubAccountFormType } from '@/types/user'
import { FileType } from '@/types/tasks'

@Component({
  components: {
    SelectCommon,
    InputCommon,
  },
})
export default class AddSubAccountForm extends Vue {
  $refs!: {
    upload: Upload
    addSubAccountRef: Form
  }

  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: String })
  role?: string

  addSubAccountForm: AddSubAccountFormType = {
    lastName: '',
    firstName: '',
    login: '',
    email: '',
    photo: null,
    vendor: '',
  }

  vendorOptions: VendorOptionsType[] = []

  get vendors(): AllVendorsType[] {
    return this.$store.getters['vendors/allVendors']
  }

  get uploadedFile(): string {
    return this.$store.getters['files/uploadedFile']
  }

  uploadPhoto(file: FileType): void {
    this.addSubAccountForm.photo = file
    this.$store.dispatch('files/uploadFile', file.raw)
  }

  handlePhotoClear(): void {
    this.$refs.upload.clearFiles()
    this.$store.commit('files/deleteFile')
    this.addSubAccountForm.photo = null
  }

  handleCloseDialog(): void {
    this.$emit('close-dialog')
  }

  handleAddSubAccount(): void {
    this.handleCloseDialog()
    this.$emit('add-sub-account', this.addSubAccountForm.email)
  }

  async handleUserCreate(): Promise<void> {
    const formData = new FormData()

    formData.append('PERSONAL_PHOTO', this.uploadedFile)
    formData.append('LOGIN', this.addSubAccountForm.login)
    formData.append('EMAIL', this.addSubAccountForm.email)
    formData.append('NAME', this.addSubAccountForm.firstName)
    formData.append('LAST_NAME', this.addSubAccountForm.lastName)
    formData.append('LOGIN', this.addSubAccountForm.login)
    formData.append('UF_VENDOR_ID', this.addSubAccountForm.vendor)

    const [error] = await usersAPI.userCreate(formData)

    if (!error) {
      this.handleAddSubAccount()

      this.$refs.addSubAccountRef.resetFields()
    }
  }

  async setVendors(): Promise<void> {
    this.vendors.forEach((vendor: AllVendorsType) => {
      this.vendorOptions.push({
        label: vendor.name,
        value: vendor.id,
      })
    })
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('vendors/getAllVendors')
    await this.setVendors()
  }
}
