














































import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { userAPI } from '@/api/user.api'
import { Message } from 'element-ui'

@Component({
  components: { InputCommon },
})
export default class AddSubAccountForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Number })
  id!: number

  @Emit()
  updateTable(): void {
    return
  }

  @Emit('close-dialog')
  handleCloseDialog(): void {
    return
  }

  reason = ''

  async handleUserDeactivate(): Promise<void> {
    const [error] = await userAPI.userBlock(this.id, this.reason)

    if (error) {
      Message({
        type: 'error',
        message: 'Произошла ошибка',
      })
    } else {
      Message({
        type: 'success',
        message: 'Пользователь деактивирован',
      })

      this.handleCloseDialog()
      this.updateTable()

      this.reason = ''
    }
  }
}
