















































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Message, MessageBox } from 'element-ui'

import { userAPI } from '@/api/user.api'

@Component
export default class SubAccountsTable extends Vue {
  @Prop({ type: Array })
  subAccounts!: object[]

  @Prop({ type: Boolean })
  loading!: boolean

  @Emit()
  updateTable(): void {
    return
  }

  @Emit('user-deactivate')
  handleUserDeactivate(id: number): number {
    return id
  }

  statuses = {
    active: 'Активный',
    new: 'Новый',
    blocked: 'Заблокированный',
  }

  subAccountsStatus(status: string): string {
    switch (status) {
      case 'active':
        return 'bg-success'
      case 'blocked':
        return 'bg-danger'
      default:
        return 'bg-primary'
    }
  }

  async handleClickUserBlock(id: string): Promise<void> {
    MessageBox.confirm(
      'У этого юзера есть связи с другими объектами, его можно только деактивировать',
      'Предупреждение',
      {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }
    ).then(() => {
      this.handleUserDeactivate(+id)
    })
  }

  async handleClickUserUnblock(id: string): Promise<void> {
    const [error] = await userAPI.userUnblock(+id)

    if (!error) {
      this.updateTable()
    }
  }

  async handleClickSendMessage(id: string): Promise<void> {
    const [error] = await userAPI.sendUserInfo(+id)

    if (!error) {
      Message({
        type: 'success',
        message: 'Сообщение успешно отправлено',
      })
    }
  }

  //TODO Не все данные
}
