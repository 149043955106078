




























































































import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { Upload } from 'element-ui'
import { UserResponseType } from '@/types/user'

export type UploadFileType = {
  raw: File
  url: string
}

export type EditProfileType = {
  lastName: string
  firstName: string
  email: string
  photo: UploadFileType | null
}

@Component({
  components: {
    InputCommon,
  },
})
export default class EditProfileForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Object })
  profileInfo!: UserResponseType

  editProfileForm: EditProfileType = {
    lastName: '',
    firstName: '',
    email: '',
    photo: null,
  }

  $refs!: {
    upload: Upload
  }

  get uploadedFile(): string {
    return this.$store.getters['files/uploadedFile']
  }

  uploadPhoto(file): void {
    this.editProfileForm.photo = file
    this.$store.dispatch('files/uploadFile', file.raw)
  }

  clearPhoto(): void {
    this.$refs.upload.clearFiles()
    this.$store.commit('files/deleteFile')
    this.editProfileForm.photo = null
  }

  handleCloseDialog(): void {
    this.clearPhoto()
    this.$emit('close-dialog')
  }

  async handleEditProfile(): Promise<void> {
    const data = {
      id: this.profileInfo.id,
      EMAIL: this.editProfileForm.email,
      NAME: this.editProfileForm.firstName,
      PERSONAL_PHOTO: this.uploadedFile,
      LAST_NAME: this.editProfileForm.lastName,
    }

    await this.$store.dispatch('users/changeUser', data)
    this.$emit('edit-profile', true)
  }
  @Watch('profileInfo')
  isProfileInfoChange(value: UserResponseType): void {
    this.editProfileForm.lastName = value.last_name
    this.editProfileForm.firstName = value.name
    this.editProfileForm.email = value.email
  }
}
